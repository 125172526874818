.badge-container {
  padding: 0;
  position: relative;
  float: left;
}

.badge-borders {
  outline: 1px dotted;
}

.badge-field {
  position: absolute;
}

.badge-image {
  width: 100%;
  height: 100%;
}
