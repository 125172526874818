* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f7f7f7;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.rastjazka {
  color: white;
  position: absolute;
  z-index: 2000;
  width: 100%;
  text-align: center;
  height: 64px;
  padding-top: 20px;
  &-error {
    background: firebrick;
  }
  &-warn {
    background: chocolate;
  }

  &.remove {
    margin-top: -100px;
  }
}

.justwidget--asst-pic {
  width: 60px !important;
  height: 60px !important;
  top: 50px !important;
  right: -10px !important;
  background-image: url('../images/zenbot_icon.svg') !important;
}

body .justwidget--asst-pic {
  animation: none !important;
}

.justwidget--asst-pic-waves {
  display: none;
}

.justwidget--asst-pic svg {
  display: none;
}


@media(max-width: 768px) {
  #widget-root {
    height: 0 !important;
  }
  .justwidget {
    width: 0;
  }

  .justwidget--asst-pic {
    display: none !important;
  }

  .justwidget--asst-pic_svg-mobile {
    display: none !important;
  }
}
