.konvajs-content {
  margin: 0 auto;
  //border-radius: 17px;
  box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.5);
}

#svg_barcode {
  position: absolute;
  left: -10000px;
  top: -10000px;
}
